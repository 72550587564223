<template>
  <b-link class="navbar-brand v-align-middle" to="#">
    <img class="navbar-brand-full" src="/img/brand/invoice-logo-2019.png" alt="RTNest Inc. Invoice Logo">
    <img class="navbar-brand-minimized" src="img/brand/invoice-logo-small.png"  alt="RTNest Inc. Invoice Logo">
  </b-link>
</template>

<script>

export default {
  name: 'Logo',
  components: {

  }
}
</script>
