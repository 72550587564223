var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "breadcrumb" },
    [
      _c(
        "b-button",
        {
          staticClass: "btn-sm d-none d-md-block back-button",
          attrs: { variant: "primary" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [_c("i", { staticClass: "fa mr-1 fa-chevron-left" }), _vm._v("Back")]
      ),
      _vm._l(_vm.routeRecords, function(routeObject, index) {
        return _c(
          "li",
          { key: index, staticClass: "breadcrumb-item" },
          [
            _vm.isLast(index)
              ? _c("span", { staticClass: "active" }, [
                  _vm._v(_vm._s(_vm.getName(routeObject)))
                ])
              : _c("router-link", { attrs: { to: routeObject } }, [
                  _vm._v(_vm._s(_vm.getName(routeObject)))
                ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }