var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-link",
    { staticClass: "navbar-brand v-align-middle", attrs: { to: "#" } },
    [
      _c("img", {
        staticClass: "navbar-brand-full",
        attrs: {
          src: "/img/brand/invoice-logo-2019.png",
          alt: "RTNest Inc. Invoice Logo"
        }
      }),
      _c("img", {
        staticClass: "navbar-brand-minimized",
        attrs: {
          src: "img/brand/invoice-logo-small.png",
          alt: "RTNest Inc. Invoice Logo"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }