<template>
  <ol class="breadcrumb">
    <b-button class="btn-sm d-none d-md-block back-button" @click="$router.go(-1)" variant="primary"><i class="fa mr-1 fa-chevron-left"></i>Back</b-button>
    <li class="breadcrumb-item" :key="index" v-for="(routeObject, index) in routeRecords">
      <span class="active" v-if="isLast(index)">{{ getName(routeObject) }}</span>
      <router-link :to="routeObject" v-else>{{ getName(routeObject) }}</router-link>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    getName (item) {
      return item.meta && item.meta.label ? item.meta.label : item.name || null
    },
    isLast (index) {
      return index === this.list.length - 1
    }
  },
  computed: {
    enviroment: function() {
      return process.env.VUE_APP_URL
    },
    routeRecords: function () {
      return this.list.filter((route) => route.name || route.meta.label)
    }
  }
}
</script>

<style>
  .breadcrumb-item {margin-top: 5px;}
</style>
